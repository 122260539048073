import icon from '../components/icons/icon.png';
import { errorType } from '../Request';

export interface PagePropsType {}

export interface responseHomeData {
    name: string;
    photo_url: string;
    code?: number;
    department?: '1' | '2' | '3';
    level?: '1' | '2' | '3' | '4';
    cgpa?: number;
    hours?: number;
    academic_year: string | null;
}

export interface PageStateType {
    currentTab: TabsName | null;
    loading: boolean;
    alert: errorType;
    homeData: responseHomeData;
}
export const Tabs = {
    // ELearning: {
    //     name: 'التعليم الالكتروني',
    //     icon: icon,
    // },
    studentServices: {
        name: 'الخدمات الطلابية',
        icon: icon,
        services: {
            RegistrationProof: 'اثبات قيد',
            StatusStatement: 'افادة',
            LostCardReplacement: 'كارنيه بدل فاقد',
        },
    },
    // studentActivities: {
    //     name: 'الانشطة الطلابية',
    //     icon: icon,
    // },
    exams: { name: 'الامتحانات الالكترونية', icon: icon },
    result: {
        name: 'النتيجة',
        icon: icon,
    },
    StudentMaterial: {
        name: 'تسجيلات المواد و السكاشن',
        icon: icon,
    },
} as const;

export type TabsName = keyof typeof Tabs;
