import React from 'react';
import styles from './Cards.module.css';

interface PropsType<CardNameType extends string> {
    cards: Record<CardNameType, string>;
    selectedCard: CardNameType | null;
    setSelectCard: (nameCard: CardNameType) => void;
}

const Cards = <CardNameType extends string>({ cards, setSelectCard, selectedCard }: PropsType<CardNameType>): React.ReactElement => {
    return (
        <div className={styles.cards}>
            {Object.entries(cards).map(([cardName, verboseName]) => (
                <p
                    className={styles.card + (selectedCard === cardName ? ' ' + styles.selected : '')}
                    key={cardName}
                    onClick={() => {
                        setSelectCard(cardName as CardNameType);
                    }}
                >
                    {verboseName as string}
                </p>
            ))}
        </div>
    );
};

export default Cards;
