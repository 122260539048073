export const departmentName = (department: '1' | '2' | '3' | undefined): string => {
    switch (department) {
        case '1':
            return 'نظم معلومات الاعمال';

        case '2':
            return 'المحاسبة والمراجعة';

        case '3':
            return 'إقتصاديات التجارة الخارجية';

        default:
            return '';
    }
};

export const levelName = (level: '1' | '2' | '3' | '4' | undefined, definite: boolean): string => {
    switch (level) {
        case '1':
            return definite ? 'الاول' : 'اولى';

        case '2':
            return definite ? 'الثاني' : 'ثانية';

        case '3':
            return definite ? 'الثالث' : 'ثالثة';

        case '4':
            return definite ? 'الرابع' : 'رابعة';

        default:
            return '';
    }
};
export const termName = (term: '1' | '2' | '3'): string => {
    switch (term) {
        case '1':
            return 'الاول';

        case '2':
            return 'الثاني';

        case '3':
            return 'الصيفي';

        default:
            return '';
    }
};
