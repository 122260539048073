import React from 'react';
import { symbolChoose } from './Exam';

const Choose: React.FC<{ onClickChoose: () => void; symbol: symbolChoose; choose: string; active: boolean }> = ({ onClickChoose, symbol, choose, active }) => {
    return (
        <div onClick={onClickChoose} className={`choose${active ? ' active' : ''}`} role="button">
            <p>{symbol}</p>
            <p>{choose}</p>
        </div>
    );
};

export default Choose;
