import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Page from './Page/Page';
import Login from './Login/Login';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="login/" element={<Login />} />
                <Route path="*" element={<Page />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
