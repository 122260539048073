import React from 'react';
import '../../Common/Table.css';
import './Result.css';
import { AxiosError } from 'axios';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { url_studentGate } from '../../public';
import { Tabs } from '../PageType';
import TitleTab from '../../components/TitleTab/TitleTab';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';
import { termName } from '../../Common/utilities';

enum EvaluationType {
    'A+' = 'ممتاز+',
    'A' = 'ممتاز',
    'A-' = 'ممتاز-',
    'B+' = 'جيد جدا+',
    'B' = 'جيد جدا',
    'B-' = 'جيد جدا-',
    'C+' = 'جيد+',
    'C' = 'جيد',
    'C-' = 'جيد-',
    'D+' = 'مقبول+',
    'D' = 'مقبول',
    'D-' = 'مقبول-',
    'F' = 'راسب',
    'FX' = 'محروم',
    'LC' = 'غير مكتمل',
    'W' = 'منسحب',
    'AB' = 'غياب',
}
const Result: React.FC = () => {
    const [resultsData, setResult] = React.useState<{
        [academic_year: string]: {
            [term in '1' | '2' | '3']: (
                | { evaluation: keyof typeof EvaluationType; student_material__open_material__material_skill__material__name: string; notes: string | null }
                | { cgpa: number }
            )[];
        };
    }>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [alert, setAlert] = React.useState<errorType>([]);

    React.useEffect(() => {
        const handleError = (error: AxiosError): void => {
            setAlert(set_error_message(error));
            setLoading(false);
        };
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        const handleDownload = (
            data: {
                evaluation: keyof typeof EvaluationType;
                student_material__student_term__term__name: '1' | '2' | '3';
                student_material__student_term__cgpa: number;
                student_material__student_term__term__academic_year__name: string;
                student_material__open_material__material_skill__material__name: string;
                notes: string | null;
            }[],
        ): void => {
            const results: typeof resultsData = {};
            data.forEach((material_degree) => {
                // results[material_degree.student_material__student_term__term__academic_year__name] = {
                //     ...(results[material_degree.student_material__student_term__term__academic_year__name] ?? {}),
                //     [material_degree.student_material__student_term__term__name]: [
                //         ...(results[material_degree.student_material__student_term__term__academic_year__name]?.[material_degree.student_material__student_term__term__name] ?? [
                //             { cgpa: material_degree.student_material__student_term__cgpa },
                //         ]),
                //         {
                //             student_material__open_material__material_skill__material__name: material_degree.student_material__open_material__material_skill__material__name,
                //             evaluation: material_degree.evaluation,
                //             notes: material_degree.notes,
                //         },
                //     ],
                // };
                const year = material_degree.student_material__student_term__term__academic_year__name;
                const term = material_degree.student_material__student_term__term__name;

                if (!results[year]) {
                    results[year] = {} as (typeof results)[string];
                }
                if (!results[year][term]) {
                    results[year][term] = [{ cgpa: material_degree.student_material__student_term__cgpa }];
                }

                results[year][term].push({
                    student_material__open_material__material_skill__material__name: material_degree.student_material__open_material__material_skill__material__name,
                    evaluation: material_degree.evaluation,
                    notes: material_degree.notes,
                });
            });
            setResult(results);
            setLoading(false);
        };
        const requestOptions = {
            params: {
                main_target: 'results',
            },
        };
        RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    }, []);

    const closeAlert = React.useCallback(() => setAlert([]), []);
    return (
        <div>
            <div className="titles">
                <TitleTab name={Tabs.result.name} />
            </div>

            {Object.keys(resultsData).length > 0 &&
                Object.entries(resultsData).map(([academic_year, terms]) =>
                    Object.entries(terms).map(([term, materials_degree]) => (
                        <React.Fragment key={`${academic_year}-${term}`}>
                            <div key={academic_year + term} className="header_table_result">
                                <p>العام الدراسي {academic_year}</p>
                                <p>الفصل الدراسي {termName(term as '1' | '2' | '3')}</p>
                            </div>
                            <table key={academic_year + term} className="table">
                                <thead className="thead">
                                    <tr className="tr">
                                        <th className="th">اسم المادة </th>
                                        <th className="th">التقيم</th>
                                        <th className="th">الملاحظات</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    {materials_degree.map((material_degree) =>
                                        'cgpa' in material_degree ? (
                                            <tr key={'cgpa'} className="tr">
                                                <td className="td">المعدل التراكمي</td>
                                                <td className="td">{material_degree.cgpa}</td>
                                                <td className="td"></td>
                                            </tr>
                                        ) : (
                                            <tr key={material_degree.student_material__open_material__material_skill__material__name} className="tr">
                                                <td className="td">{material_degree.student_material__open_material__material_skill__material__name}</td>
                                                <td className="td">{EvaluationType[material_degree.evaluation]}</td>
                                                <td className="td">{material_degree.notes}</td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )),
                )}
            {alert.length > 0 && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={alert}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAlert([]);
                            }}
                        />
                    }
                />
            )}

            {loading && <Loading />}
        </div>
    );
};

export default Result;
