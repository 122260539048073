import React from 'react';
import './Exam.css';
import { AxiosError } from 'axios';
import { errorType, RequestGET, RequestPOST, set_error_message } from '../../Request';
import { url_studentGate } from '../../public';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';
import Timer from './Timer';
import Choose from './Choose';
import BtnSuccess from '../../components/Buttons/BtnSuccess/BtnSuccess';

export enum symbolChoose {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
}

const Exam: React.FC = () => {
    const [examData, setexamData] = React.useState<{
        pk: number;
        start_date: string;
        end_date: string;
        material_name: string;
        now: string;
        questions: {
            pk: number;
            head: string | null;
            head_image: string;
            A: string;
            B: string;
            C: string | null;
            D: string | null;
        }[];
    } | null>(null);
    const [currentIndexQuestion, setCurrentIndexQuestion] = React.useState(0);
    const [answers, setAnswers] = React.useState<{ [pk: number]: symbolChoose }>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [sending, setSending] = React.useState<null | 'sure' | 'sending'>(null);
    const [alert, setAlert] = React.useState<errorType>([]);

    const handleError = (error: AxiosError): void => {
        setAlert(set_error_message(error));
        setLoading(false);
        setSending(null);
    };
    const sendanswers = (finish: boolean = true) => {
        let bodyFormData = new FormData();
        bodyFormData.append('main_target', 'exam');
        bodyFormData.append('exame', String(examData?.pk));
        bodyFormData.append('answers', JSON.stringify(answers));
        bodyFormData.append('finish', String(finish));
        RequestPOST(
            url_studentGate,
            bodyFormData,
            () => {
                setAlert(['تم تسجيل اجابتك بنجاح يرجي الدخول مره اخري لتعقب ميعاد الامتحان القادم']);
                setSending(null);
            },
            handleError,
        );
        setSending('sending');
    };

    React.useEffect(() => {
        const handleDownload = (data: NonNullable<typeof examData>): void => {
            setexamData(data);
            setLoading(false);
        };
        const requestOptions = {
            params: {
                main_target: 'exam',
            },
        };
        RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    }, []);

    const closeAlert = React.useCallback(() => setAlert([]), []);
    const currentQuestion = React.useMemo(() => {
        return examData?.questions[currentIndexQuestion];
    }, [currentIndexQuestion, examData]);

    const handleChooseAnswer = React.useCallback(
        (symbol: symbolChoose) => {
            if (currentQuestion) {
                setAnswers((prev) => ({ ...prev, [currentQuestion.pk]: symbol }));
            }
        },
        [currentQuestion],
    );

    const handleNextQuestion = React.useCallback(() => {
        setCurrentIndexQuestion((prevIndex) => prevIndex + 1);
    }, []);

    const handlePreviousQuestion = React.useCallback(() => {
        setCurrentIndexQuestion((prevIndex) => prevIndex - 1);
    }, []);
    const handleEndExam = React.useCallback(() => {
        setSending('sure');
    }, []);
    return (
        <>
            {examData && (
                <div className="exam">
                    <div className="exam_title">
                        <p>{examData.material_name}</p>
                        <Timer now={examData.now} end_date={examData.end_date} sendanswers={sendanswers} />
                    </div>
                    <div className="content">
                        <div className="question">
                            {currentQuestion?.head_image && <img alt="head_image" src={'https://student.rayaedu.org' + currentQuestion.head_image} />}
                            {currentQuestion?.head && <p>{currentQuestion.head}</p>}

                            <div className="chooses">
                                {['A', 'B', 'C', 'D'].map((symbol) => {
                                    const answer = symbolChoose[symbol as keyof typeof symbolChoose];
                                    const chooseText = currentQuestion?.[symbol as keyof typeof currentQuestion];
                                    return (
                                        chooseText && (
                                            <Choose
                                                key={symbol}
                                                active={answers[currentQuestion.pk] === answer}
                                                symbol={answer}
                                                choose={String(chooseText)}
                                                onClickChoose={() => handleChooseAnswer(answer)}
                                            />
                                        )
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="arrows">
                            <button
                                onClick={handleNextQuestion}
                                disabled={currentIndexQuestion >= examData.questions.length - 1}
                                className={currentIndexQuestion >= examData.questions.length - 1 ? 'disabled' : undefined}
                            >
                                التالي
                            </button>
                            <button onClick={handlePreviousQuestion} disabled={currentIndexQuestion <= 0} className={currentIndexQuestion <= 0 ? 'disabled' : undefined}>
                                السابق
                            </button>
                        </div>
                        <button onClick={handleEndExam}>انهاء الامتحان</button>
                        <div className="questions">
                            {examData.questions.map((question, index) => (
                                <button
                                    key={question.pk}
                                    className={currentIndexQuestion === index ? 'active' : undefined}
                                    onClick={() => {
                                        setCurrentIndexQuestion(index);
                                    }}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {alert.length > 0 && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={alert}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAlert([]);
                            }}
                        />
                    }
                />
            )}
            {sending === 'sure' && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={[`هل انت متاكد من انهاء الامتحان و تم حل عدد ${Object.keys(answers).length} من اصل ${examData?.questions.length} من الاسئلة`]}
                    btns={
                        <>
                            <BtnPrimary
                                verbose_name="الغاء"
                                OnClickBtn={() => {
                                    setSending(null);
                                }}
                            />
                            <BtnSuccess
                                verbose_name="انهاء و ارسال الامتحان"
                                OnClickBtn={() => {
                                    sendanswers();
                                }}
                            />
                        </>
                    }
                />
            )}
            {(loading || sending === 'sending') && <Loading />}
        </>
    );
};

export default Exam;
