import React from 'react';
import './SubTitle.css';
interface TitleTabProps {
    name: string;
}

const SubTitle: React.FC<TitleTabProps> = (props) => {
    return <p className="Subtitle">{props.name}</p>;
};

export default SubTitle;
