import React from 'react';
import './FormService.css';
import { Tabs } from '../../PageType';
import { errorType, RequestPOST, set_error_message } from '../../../Request';
import { AxiosError } from 'axios';
import { ServiceType, studentServiceType } from '../StudentService';
import Alert from '../../../components/Alert/Alert';
import BtnPrimary from '../../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../../components/Loading/Loading';
import { url_studentGate } from '../../../public';

const tab = Tabs.studentServices;
type nameCardType = keyof typeof tab.services;
interface FormServiceProps {
    selectedService: nameCardType;
    setService: (selectedService: nameCardType | null) => void;
    setStudentServices: (selectedService: studentServiceType[]) => void;
}

const FormService: React.FC<FormServiceProps> = (props) => {
    const [addressed, setAddressed] = React.useState<string | null>(null);
    const [notes_student, setNotes_student] = React.useState<string | null>(null);
    const [loadingSend, setLoadingSend] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<errorType>([]);
    React.useEffect(() => {
        const handleError = (error: AxiosError): void => {
            setAlert(set_error_message(error));
            setLoadingSend(false);
        };
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        const handleDownload = (data: studentServiceType[]): void => {
            props.setStudentServices(data);
            props.setService(null);
            setLoadingSend(false);
        };
        if (loadingSend) {
            const bodyFormData = new FormData();
            console.log(ServiceType,props.selectedService,ServiceType[props.selectedService as keyof typeof ServiceType])
            bodyFormData.append('service', ServiceType[props.selectedService as keyof typeof ServiceType]);
            bodyFormData.append('main_target', 'studentService');
            if (addressed) bodyFormData.append('addressed', addressed);
            if (notes_student) bodyFormData.append('notes_student', notes_student);
            RequestPOST(url_studentGate, bodyFormData, handleDownload, handleError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSend]);
    const closeAlert = React.useCallback(() => {
        setAlert([]);
    }, []);
    return (
        <div className="form_service">
            {['RegistrationProof', 'StatusStatement'].includes(props.selectedService) && (
                <>
                    <p>اذكر الجهة او المؤسسة الموجه اليها {tab.services[props.selectedService]} *</p>
                    <input
                        type="text"
                        value={addressed ?? undefined}
                        onChange={(e) => {
                            setAddressed(e.target.value);
                        }}
                    />
                </>
            )}
            <p>ملاحظاتك</p>
            <input
                type="text"
                value={notes_student ?? undefined}
                onChange={(e) => {
                    setNotes_student(e.target.value);
                }}
            />
            <div className="btns_service">
                {(addressed || props.selectedService === 'LostCardReplacement') && (
                    <button
                        className="send_service"
                        onClick={() => {
                            setLoadingSend(true);
                        }}
                    >
                        تقديم الطلب
                    </button>
                )}
                <button
                    onClick={() => {
                        props.setService(null);
                    }}
                    className="cancel_service"
                >
                    الغاء
                </button>
            </div>
            {alert.length > 0 && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={alert}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAlert([]);
                            }}
                        />
                    }
                />
            )}

            {loadingSend && <Loading />}
        </div>
    );
};

export default FormService;
