import React from 'react';
import './Menu.css';
import { MenuProps } from './MenuTypes';
import { Tabs, TabsName } from '../PageType';
// import ConfgPage from '../Header/Config/ConfgPage';
const Menu: React.FC<MenuProps> = (props) => {
    const MenuRef = React.useRef<HTMLDivElement>(null);
    const [display, setDisplay] = React.useState(false);
    //////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        // Add or remove event listener based on conditions
        const handleClickOutside = (event: MouseEvent) => {
            if (MenuRef.current && !MenuRef.current.contains(event.target as Node)) {
                setDisplay(!display);
            }
        };
        if (display) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [display]);
    //////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////
    return props.currentTab ? (
        <div className="sidebar">
            <i
                className={'collapse ' + (display ? 'fa fa-sign-out right' : 'fa fa-sign-in left')}
                onClick={() => {
                    setDisplay(!display);
                }}
            ></i>
            {display ? (
                <div ref={MenuRef} className="menu">
                    {Object.entries(Tabs).map(([tabName, tabData]) => (
                        <div
                            key={tabName}
                            className="tab"
                            onClick={() => {
                                setDisplay(!display);
                                props.setCurrentTab(tabName as TabsName);
                            }}
                        >
                            <img alt="icon" src={tabData.icon} />
                            <p className={props.currentTab === tabName ? 'selected' : undefined}>{tabData.name}</p>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    ) : null;
};

export default Menu;
