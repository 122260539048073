import React, { memo } from 'react';

const Timer: React.FC<{ now: string; end_date: string; sendanswers: (finish: boolean) => void }> = ({ now, end_date, sendanswers }) => {
    const [counter, setCounter] = React.useState<number>(0);
    React.useEffect(() => {
        const startTime = new Date(now).getTime();
        const endTime = new Date(end_date).getTime();
        const differenceInMilliseconds = endTime - startTime;
        if (differenceInMilliseconds > 0) {
            const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
            setCounter(differenceInSeconds);
            const intervalId = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter <= 1) {
                        clearInterval(intervalId);
                        sendanswers(false);
                        return 0;
                    }
                    return prevCounter - 1;
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [now, end_date,sendanswers]);

    const stringCounter = React.useMemo(() => {
        const totalMinutes = Math.floor(counter / 60);
        const seconds = counter % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return {
            h: hours < 10 ? `0${hours}` : hours,
            m: minutes < 10 ? `0${minutes}` : minutes,
            s: seconds < 10 ? `0${seconds}` : seconds,
        };
    }, [counter]);
    const redTextStyle = React.useMemo(() => ({ color: 'red' }), []);
    return (
        <p style={counter < 61 ? redTextStyle : undefined}>
            {stringCounter.h}:{stringCounter.m}:{stringCounter.s}
        </p>
    );
};

export default memo(Timer, (prevProps, nextProps) => {
    return prevProps.now === nextProps.now && prevProps.end_date === nextProps.end_date;
});
