import React from 'react';
import './TitleTab.css'
interface TitleTabProps {
    name: string;
}

const TitleTab: React.FC<TitleTabProps> = (props) => {
    return <p className="titleTab">{props.name}</p>;
};

export default TitleTab;
