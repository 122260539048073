import React from 'react';
import './Page.css';
import logo from '../components/img/logo_raya.png';
import { PagePropsType, PageStateType, responseHomeData, Tabs, TabsName } from './PageType';
import Menu from './Menu/Menu';
import StudentService from './StudentService/StudentService';
import BtnPrimary from '../components/Buttons/BtnPrimary/BtnPrimary';
import Alert from '../components/Alert/Alert';
import Loading from '../components/Loading/Loading';
import { RequestGET, set_error_message } from '../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../public';
import { departmentName, levelName } from '../Common/utilities';
import Result from './Result/Result';
import Exam from './Exam/Exam';
import StudentMaterial from './StudentMaterial/StudentMaterial';

class Page extends React.Component<PagePropsType, PageStateType> {
    state: Readonly<PageStateType> = {
        currentTab: null,
        loading: true,
        alert: [],
        homeData: {
            name: '',
            photo_url: '',
            academic_year: null,
        },
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    SetError = (error: AxiosError): void => {
        this.setState({ alert: set_error_message(error), loading: false });
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    Download = (data: responseHomeData): void => {
        this.setState({ homeData: data, loading: false });
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    GetData(): void {
        if (this.state.loading) {
            const requestOptions = {
                params: {
                    main_target: 'home',
                },
            };
            RequestGET(url_studentGate, requestOptions, this.Download, this.SetError);
        }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////
    componentDidMount() {
        this.GetData();
    }
    //////////////////////////////////////////////////////////////////////////////
    componentDidUpdate() {
        this.GetData();
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////
    render(): React.ReactNode {
        return (
            <>
                <Menu
                    currentTab={this.state.currentTab}
                    setCurrentTab={(tabName) => {
                        this.setState({
                            currentTab: tabName,
                        });
                    }}
                />
                {this.state.currentTab !== 'exams' ? (
                    <div className="page">
                        <div className="page_title">
                            <div className="student_data">
                                <p>{this.state.homeData.name}</p>
                                <p>
                                    المستوي {levelName(this.state.homeData.level, true)}-{departmentName(this.state.homeData.department)}
                                </p>
                                <p>العام الجامعي {this.state.homeData.academic_year}</p>
                            </div>
                            <div className="academic_data">
                                <div>
                                    <p>كود الطالب</p>
                                    <p>{this.state.homeData.code}</p>
                                </div>
                                <div>
                                    <p>المعدل التراكمي</p>
                                    <p>{this.state.homeData.cgpa}</p>
                                </div>
                                <div>
                                    <p>الساعات المجتازة</p>
                                    <p>{this.state.homeData.hours}</p>
                                </div>
                            </div>
                            <img alt={'صورة الطالب'} src={`https://student.rayaedu.org`+this.state.homeData.photo_url} />
                        </div>
                        <div className="content">
                            {this.state.currentTab === null ? (
                                <div className="tabs">
                                    {Object.entries(Tabs).map(([tabName, tabData]) => (
                                        <div
                                            key={tabName}
                                            className="tab"
                                            onClick={() => {
                                                this.setState({
                                                    currentTab: tabName as TabsName,
                                                });
                                            }}
                                        >
                                            <img alt="icon" src={tabData.icon} />
                                            <p>{tabData.name}</p>
                                        </div>
                                    ))}
                                </div>
                            ) : this.state.currentTab === 'studentServices' ? (
                                <StudentService />
                            ) : this.state.currentTab === 'result' ? (
                                <Result />
                            ) : this.state.currentTab === 'StudentMaterial' ? (
                                <StudentMaterial />
                            ) : null}
                        </div>
                        <div className="footer">
                            <img
                                onClick={() => {
                                    this.setState({ currentTab: null });
                                }}
                                alt="logo"
                                src={logo}
                            />
                        </div>
                    </div>
                ) : (
                    <Exam />
                )}
                {this.state.alert.length ? (
                    <Alert
                        title={'الملاحظات'}
                        closeAlert={() => {
                            this.setState({ alert: [] });
                        }}
                        notes={this.state.alert}
                        btns={
                            <BtnPrimary
                                verbose_name="الغاء"
                                OnClickBtn={() => {
                                    this.setState({ alert: [] });
                                }}
                            />
                        }
                    />
                ) : null}
                {this.state.loading ? <Loading /> : null}
            </>
        );
    }
}

export default Page;
