import React from 'react';
import '../../Common/Table.css';
import { AxiosError } from 'axios';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { url_studentGate } from '../../public';
import { Tabs } from '../PageType';
import TitleTab from '../../components/TitleTab/TitleTab';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';
import { termName } from '../../Common/utilities';

const StudentMaterial: React.FC = () => {
    const [StudentMaterialsData, setStudentMaterials] = React.useState<{
        [academic_year: string]: {
            [term in '1' | '2' | '3']: { open_material__material_skill__material__name: string; section_number: number | null }[];
        };
    }>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [alert, setAlert] = React.useState<errorType>([]);

    React.useEffect(() => {
        const handleError = (error: AxiosError): void => {
            setAlert(set_error_message(error));
            setLoading(false);
        };
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        const handleDownload = (
            data: {
                student_term__term__name: '1' | '2' | '3';
                student_term__term__academic_year__name: string;
                open_material__material_skill__material__name: string;
                section_number: number | null;
            }[],
        ): void => {
            if (data.length === 0) {
                setAlert(['لم يتم تاكيد تسجيلك بعد']);
                setLoading(false);
                return;
            }
            const materials: typeof StudentMaterialsData = {};
            data.forEach((material_degree) => {
                const year = material_degree.student_term__term__academic_year__name;
                const term = material_degree.student_term__term__name;
                if (!materials[year]) {
                    materials[year] = {} as (typeof materials)[string];
                }
                if (!materials[year][term]) {
                    materials[year][term] = [];
                }
                materials[year][term].push({
                    open_material__material_skill__material__name: material_degree.open_material__material_skill__material__name,
                    section_number: material_degree.section_number,
                });
            });
            setStudentMaterials(materials);
            setLoading(false);
        };
        const requestOptions = {
            params: {
                main_target: 'StudentMaterial',
            },
        };
        RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    }, []);

    const closeAlert = React.useCallback(() => setAlert([]), []);
    return (
        <div>
            <div className="titles">
                <TitleTab name={Tabs.StudentMaterial.name} />
            </div>

            {Object.keys(StudentMaterialsData).length > 0 &&
                Object.entries(StudentMaterialsData).map(([academic_year, terms]) =>
                    Object.entries(terms).map(([term, materials_degree]) => (
                        <React.Fragment key={`${academic_year}-${term}`}>
                            <div key={academic_year + term} className="header_table_result">
                                <p>العام الدراسي {academic_year}</p>
                                <p>الفصل الدراسي {termName(term as '1' | '2' | '3')}</p>
                            </div>
                            <table key={academic_year + term} className="table">
                                <thead className="thead">
                                    <tr className="tr">
                                        <th className="th">م</th>
                                        <th className="th">اسم المادة </th>
                                        <th className="th">رقم السكشن</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    {materials_degree.map((material_degree, index) => (
                                        <tr key={material_degree.open_material__material_skill__material__name} className="tr">
                                            <td className="td">{index + 1}</td>
                                            <td className="td">{material_degree.open_material__material_skill__material__name}</td>
                                            <td className="td">{material_degree.section_number ?? 'لم يتم التسجيل في سكشن'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )),
                )}
            {alert.length > 0 && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={alert}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAlert([]);
                            }}
                        />
                    }
                />
            )}

            {loading && <Loading />}
        </div>
    );
};

export default StudentMaterial;
