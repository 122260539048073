import React from 'react';
import './BtnPrimary.css';
import '../Buttons.css';

interface BtnPrimaryProps {
    append_class?: string;
    OnClickBtn?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    verbose_name: string;
}

const BtnPrimary: React.FC<BtnPrimaryProps> = (props: BtnPrimaryProps) => {
    return (
        <button className={props.append_class ? `btn-dark ${props.append_class}` : 'btn-dark'} type="button" onClick={props.OnClickBtn}>
            {props.verbose_name}
        </button>
    );
};

export default BtnPrimary;
