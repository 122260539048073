import React from 'react';
import '../../Common/Table.css';
import TitleTab from '../../components/TitleTab/TitleTab';
import SubTitle from '../../components/SubTitle/SubTitle';
import { Tabs } from '../PageType';
import Cards from '../../components/Cards/Cards';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../public';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';
import FormService from './FormService/FormService';

enum ServiceStatus {
    New = '1',
    Work = '2',
    Stop = '3',
    Finsh = '4',
    Delivered = '5',
}

export interface studentServiceType {
    service: '1' | '2' | '3' | '4' | '5' | '6';
    status: ServiceStatus;
    accept_date: string;
    addressed: string | null;
    notes_student: string | null;
    notes: string | null;
}
export enum ServiceType {
    RegistrationProof = '1',
    Certificate = '2',
    Card = '3',
    LostCardReplacement = '4',
    StatusStatement = '5',
    CardStamp = '6',
}

const servicesNames: Record<ServiceType, string> = {
    [ServiceType.RegistrationProof]: 'اثبات قيد',
    [ServiceType.Certificate]: 'افادة',
    [ServiceType.Card]: 'الكارنيه',
    [ServiceType.LostCardReplacement]: 'بدل فاقد كارنيه',
    [ServiceType.StatusStatement]: 'بيان حالة',
    [ServiceType.CardStamp]: 'ختم بطاقة',
};

const servicesStatus_CHOICESNames: Record<ServiceStatus, string> = {
    [ServiceStatus.New]: 'جديد',
    [ServiceStatus.Work]: 'جاري العمل عليها',
    [ServiceStatus.Stop]: 'وقفت لتواجد مشكلة',
    [ServiceStatus.Finsh]: 'انتهاء الخدمة',
    [ServiceStatus.Delivered]: 'تم التسليم',
};
const StudentService: React.FC = () => {
    const [selectedService, setService] = React.useState<nameCardType | null>(null);
    const [studentServices, setStudentServices] = React.useState<studentServiceType[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [alert, setAlert] = React.useState<errorType>([]);

    React.useEffect(() => {
        const handleError = (error: AxiosError): void => {
            setAlert(set_error_message(error));
            setLoading(false);
        };
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        const handleDownload = (data: studentServiceType[]): void => {
            setStudentServices(data);
            setLoading(false);
        };
        const requestOptions = {
            params: {
                main_target: 'studentService',
            },
        };
        RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    }, []);

    const tab = Tabs.studentServices;
    type nameCardType = keyof typeof tab.services;
    const cardsData = React.useMemo(() => {
        return Object.keys(tab.services).reduce(
            (acc, key) => ({
                ...acc,
                [key]: 'اضافة ' + tab.services[key as keyof typeof tab.services] + ' جديد',
            }),
            {} as Record<keyof typeof tab.services, string>,
        );
    }, [tab]);
    const closeAlert = React.useCallback(() => {
        setAlert([]);
    }, []);
    return (
        <div>
            <div className="titles">
                <TitleTab name={tab.name} />
                {selectedService && <SubTitle name={tab.services[selectedService]} />}
            </div>
            <Cards<nameCardType> cards={cardsData} setSelectCard={setService} selectedCard={selectedService} />
            {selectedService == null && studentServices.length > 0 && (
                <table className="table">
                    <thead className="thead">
                        <tr className="tr">
                            <th className="th">نوع الخدمة</th>
                            <th className="th">حالة الخدمة</th>
                            <th className="th">تاريخ الخدمة</th>
                            <th className="th">موجه الي</th>
                            <th className="th">ملاحظاتك</th>
                            <th className="th">ملاحظات الادمن</th>
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {studentServices.map((service, index) => (
                            <tr key={index} className="tr">
                                <td className="td">{servicesNames[service.service]}</td>
                                <td className="td">{servicesStatus_CHOICESNames[service.status]}</td>
                                <td className="td">{service.accept_date}</td>
                                <td className="td">{service.addressed}</td>
                                <td className="td">{service.notes_student}</td>
                                <td className="td">{service.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {selectedService !== null && <FormService selectedService={selectedService} setService={setService} setStudentServices={setStudentServices} />}
            {alert.length > 0 && (
                <Alert
                    title={'الملاحظات'}
                    closeAlert={closeAlert}
                    notes={alert}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAlert([]);
                            }}
                        />
                    }
                />
            )}

            {loading && <Loading />}
        </div>
    );
};

export default StudentService;
