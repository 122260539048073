import React from 'react';

export default function useOutsideAlerter(
    ref: React.MutableRefObject<HTMLElement | null>,
    setInSide: (value: boolean) => void,
    return_true = false,
    event_name: 'mousedown' | 'dblclick' = 'mousedown',
): void {
    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setInSide(false);
            } else if (return_true) {
                setInSide(true);
            }
        }

        // Bind the event listener
        document.addEventListener(event_name, handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(event_name, handleClickOutside);
        };
    }, [ref, setInSide, return_true, event_name]);
}
